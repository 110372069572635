import React from "react";
import Container from "react-bootstrap/Container";
import { useRef, useEffect, useState } from "react";
import { Link, animateScroll as scroll } from "react-scroll";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import "bootstrap/dist/css/bootstrap.css";
import "../styles/NavBar.css";
import logo from "../assets/logo.png";

function NavBar() {
  const [navBarClass, setNavBarClass] = useState(" navBarNoShadow");
  const [scrollPosition, setScrollPosition] = useState(0);

  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const navBar = useRef<HTMLElement>(null);
  window.onscroll = function () {
    if (scrollPosition < 10) {
      setNavBarClass(" navBarNoShadow");
    } else {
      setNavBarClass(" navBarShadow");
    }
  };

  const scrollToTop = () => {
    scroll.scrollToTop();
  };

  return (
    <Navbar
      collapseOnSelect
      expand="lg"
      fixed="top"
      className={"navBar" + navBarClass}
      ref={navBar}
    >
      <Container>
        <Navbar.Brand onClick={scrollToTop}>
          <img
            src={logo}
            width="40"
            height="40"
            className="d-inline-block align-top logo"
            alt="logo"
          />
        </Navbar.Brand>
        <Navbar.Brand onClick={scrollToTop} className="navBarBrand">
          Julie Park
        </Navbar.Brand>
        <Navbar.Toggle
          aria-controls="responsive-navbar-nav"
          id="navBarToggler"
        />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav
            className="justify-content-end menuContainer"
            style={{ width: "100%" }}
          >
            <Link
              activeClass="activeMenu"
              to="about"
              spy={true}
              smooth={true}
              offset={0}
              duration={500}
            >
              <div className="navItem">About</div>
            </Link>
            <Link
              activeClass="activeMenu"
              to="work"
              spy={true}
              smooth={true}
              offset={0}
              duration={500}
            >
              <div className="navItem">Work</div>
            </Link>
            <Link
              activeClass="activeMenu"
              to="contacts"
              spy={true}
              smooth={true}
              offset={0}
              duration={500}
            >
              <div className="navItem">Contact</div>
            </Link>
            <div className="navLinksContainer">
              <a
                className="navLinks"
                href="https://www.linkedin.com/in/julie-park-developer/"
              >
                <svg
                  fill="currentColor"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 50 50"
                  width="25px"
                  height="25px"
                >
                  <path d="M25,2C12.318,2,2,12.317,2,25s10.318,23,23,23s23-10.317,23-23S37.682,2,25,2z M18,35h-4V20h4V35z M16,17 c-1.105,0-2-0.895-2-2c0-1.105,0.895-2,2-2s2,0.895,2,2C18,16.105,17.105,17,16,17z M37,35h-4v-5v-2.5c0-1.925-1.575-3.5-3.5-3.5 S26,25.575,26,27.5V35h-4V20h4v1.816C27.168,20.694,28.752,20,30.5,20c3.59,0,6.5,2.91,6.5,6.5V35z" />
                </svg>
              </a>
              <a className="navLinks" href="https://github.com/jpark0224">
                <svg
                  fill="currentColor"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 1 22 22"
                  width="25px"
                  height="25px"
                >
                  <path d="M10.9,2.1c-4.6,0.5-8.3,4.2-8.8,8.7c-0.5,4.7,2.2,8.9,6.3,10.5C8.7,21.4,9,21.2,9,20.8v-1.6c0,0-0.4,0.1-0.9,0.1 c-1.4,0-2-1.2-2.1-1.9c-0.1-0.4-0.3-0.7-0.6-1C5.1,16.3,5,16.3,5,16.2C5,16,5.3,16,5.4,16c0.6,0,1.1,0.7,1.3,1c0.5,0.8,1.1,1,1.4,1 c0.4,0,0.7-0.1,0.9-0.2c0.1-0.7,0.4-1.4,1-1.8c-2.3-0.5-4-1.8-4-4c0-1.1,0.5-2.2,1.2-3C7.1,8.8,7,8.3,7,7.6c0-0.4,0-0.9,0.2-1.3 C7.2,6.1,7.4,6,7.5,6c0,0,0.1,0,0.1,0C8.1,6.1,9.1,6.4,10,7.3C10.6,7.1,11.3,7,12,7s1.4,0.1,2,0.3c0.9-0.9,2-1.2,2.5-1.3 c0,0,0.1,0,0.1,0c0.2,0,0.3,0.1,0.4,0.3C17,6.7,17,7.2,17,7.6c0,0.8-0.1,1.2-0.2,1.4c0.7,0.8,1.2,1.8,1.2,3c0,2.2-1.7,3.5-4,4 c0.6,0.5,1,1.4,1,2.3v2.6c0,0.3,0.3,0.6,0.7,0.5c3.7-1.5,6.3-5.1,6.3-9.3C22,6.1,16.9,1.4,10.9,2.1z" />
                </svg>
              </a>
            </div>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default NavBar;
